<template>
  <div>
    <v-container>
      <v-row>
        <v-col xl="10" class="mx-auto">
          <div
            class="my-5 my-lg-16 rounded-lg pa-5 border overflow-hidden shadow-light"
            style="background: #fff"
          >
            <v-row no-gutters align="start">
              <v-col
                v-if="$store.getters['app/banners'].registration_page.img"
                cols="12"
                lg="6"
                order="2"
                order-lg="1"
                class="lh-0"
              >
                <banner
                  :loading="false"
                  :banner="$store.getters['app/banners'].registration_page"
                  class="mt-5 mt-lg-0"
                />
              </v-col>
              <v-col cols="12" order="1" order-lg="2" lg="6">
                <div class="px-lg-7">
                  <h1 class="text-uppercase lh-1 mb-5">
                    <span class="fs-22 fw-700 primary--text">
                      {{ $t('welcome_to') }}
                    </span>
                    <span class="d-block display-1 fw-900 primary-vi">
                      {{ $store.getters['app/appName'] }}
                    </span>
                  </h1>
                  <v-form
                    ref="loginForm"
                    lazy-validation
                    @submit.prevent="register()"
                  >
                    <div class="mb-4">
                      <div class="mb-1 fs-13 fw-500 primary--text">
                        {{ $t('full_name') }}
                      </div>
                      <v-text-field
                        background-color="#ECFFFC"
                        :placeholder="$t('full_name')"
                        type="text"
                        v-model="form.name"
                        :error-messages="nameErrors"
                        @blur="$v.form.name.$touch()"
                        hide-details="auto"
                        required
                        outlined
                      ></v-text-field>
                    </div>
                    <div
                      class="mb-4"
                      v-if="
                        authSettings.customer_login_with == 'phone' ||
                          authSettings.customer_login_with == 'email_phone'
                      "
                    >
                      <div class="mb-1 fs-13 fw-500 primary--text">
                        {{ $t('phone_number') }}
                      </div>
                      <vue-tel-input
                        v-model="form.phone"
                        v-bind="mobileInputProps"
                        :preferred-countries="availableCountries"
                        :auto-format="false"
                        @country-changed="onCountryChanged"
                        @validate="phoneValidate"
                      >
                        <template slot="arrow-icon">
                          <span style="margin: 0 3px;">{{ dialCode ? `+${dialCode}` : '' }}</span>
                          <span class="vti__dropdown-arrow">&nbsp;▼</span>
                        </template>
                      </vue-tel-input>
                      <div
                        class="v-text-field__details mt-2 pl-3"
                        v-if="$v.form.phone.$error"
                      >
                        <div
                          class="v-messages theme--light error--text"
                          role="alert"
                        >
                          <div class="v-messages__wrapper">
                            <div class="v-messages__message">
                              {{ $t('this_field_is_required') }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="v-text-field__details mt-2 pl-3"
                        v-if="!$v.form.phone.$error && form.showInvalidPhone"
                      >
                        <div
                          class="v-messages theme--light error--text"
                          role="alert"
                        >
                          <div class="v-messages__wrapper">
                            <div class="v-messages__message">
                              {{ $t('phone_number_must_be_valid') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="mb-4"
                      v-if="
                        authSettings.customer_login_with == 'email' ||
                          authSettings.customer_login_with == 'email_phone'
                      "
                    >
                      <div class="fs-13 fw-500 primary--text">
                        {{ $t('email_address') }}
                      </div>
                      <v-text-field
                        background-color="#ECFFFC"
                        :placeholder="$t('email_address')"
                        type="email"
                        v-model="form.email"
                        :error-messages="emailErrors"
                        @blur="$v.form.email.$touch()"
                        hide-details="auto"
                        required
                        outlined
                      ></v-text-field>
                    </div>

                    <!-- Verification Code -->
                    <div v-if="registerVerifySwitch" class="mb-4">
                      <div class="fs-13 fw-500 primary--text">
                        {{ $t('verification_code') }}
                      </div>

                      <v-row dense>
                        <v-col>
                          <v-otp-input
                          background-color="#ECFFFC"
                            v-model="form.verificationCode"
                            length="5"
                            type="number"
                            :error-messages="verificationCodeErrors"
                            hide-details="auto"
                            :disabled="loading"
                            required
                          ></v-otp-input>
                        </v-col>
                        <v-col cols="auto" style="padding-top: 8px;">
                          <v-btn
                            :disabled="this.countdownSeconds !== 60"
                            block
                            color="primary"
                            elevation="0"
                            @click.stop.prevent="onSend"
                          >
                            {{ countdownText }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>

                    <div v-if="showRegisterTypeSwitch" class="form-group mb-2" style="color: rgba(0,0,0,0.5)">
                      <v-btn
                      color="#ECFFFC"
                        class="p-0 opacity-50 text-reset h-20px"
                        elevation="0"
                        @click="switchMode"

                      >
                        {{
                          authSettings.customer_login_with == 'email'
                            ? $t('change_phone')
                            : $t('change_email')
                        }}
                      </v-btn>
                    </div>

                    <div class="mb-4">
                      <div class="mb-1 fs-13 fw-500 primary--text">
                        {{ $t('password') }}
                      </div>
                      <v-text-field
                        background-color="#ECFFFC"
                        placeholder="* * * * * * * *"
                        v-model="form.password"
                        :error-messages="passwordErrors"
                        @blur="$v.form.password.$touch()"
                        type="password"
                        class="input-group--focused"
                        hide-details="auto"
                        required
                        outlined
                      ></v-text-field>
                    </div>
                    <div class="mb-4">
                      <div class="mb-1 fs-13 fw-500 primary--text">
                        {{ $t('confirm_password') }}
                      </div>
                      <v-text-field
                        background-color="#ECFFFC"
                        placeholder="* * * * * * * *"
                        v-model="form.confirmPassword"
                        :error-messages="confirmPasswordErrors"
                        @blur="$v.form.confirmPassword.$touch()"
                        type="password"
                        class="input-group--focused"
                        hide-details="auto"
                        required
                        outlined
                      ></v-text-field>
                    </div>
                    <div class="mb-4">
                      {{ $t('by_signing_up_you_agree_to_our') }}
                      <router-link
                        :to="{
                          name: 'CustomPage',
                          params: { pageSlug: 'terms' }
                        }"
                        class="primary--text text-decoration-underline"
                      >
                        {{ $t('terms_and_conditions') }}
                      </router-link>
                    </div>
                    <v-btn
                      x-large
                      block
                      class="px-12 mb-4"
                      elevation="0"
                      type="submit"
                      color="primary"
                      :loading="loading"
                      :disabled="loading"
                    >
                      {{ $t('create_account') }}
                    </v-btn>
                  </v-form>
                  <div
                    v-if="
                      generalSettings.social_login.facebook == 1 ||
                        generalSettings.social_login.twitter == 1 ||
                        generalSettings.social_login.google == 1
                    "
                  >
                    <div class="d-flex align-center mb-3">
                      <span class="me-2 fs-13 fw-500 opacity-60">
                        {{ $t('or') }}, {{ $t('join_with') }}
                      </span>a
                      <v-divider></v-divider>
                    </div>
                    <SocialLogin class="mb-5" />
                  </div>
                  <div>
                    {{ $t('already_have_an_account') }},
                    <router-link
                      :to="{ name: 'Login' }"
                      class="primary--texttext-decoration-underline primary--text"
                    >
                      {{ $t('login') }}
                    </router-link>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- 滑块验证弹出窗 -->
    <v-dialog
      v-model="dialogSwitch"
      persistent
      width="310px"
    >
      <v-card class="dialog-container">
        <div class="dialog-close c-pointer" @click="dialogSwitch = false">
          <i class="la la-close fs-20"></i>
        </div>

        <drag-verify-img-chip 
          ref="dvic"
          :imgsrc="require('@/assets/img/verified_image.png')"
          :isPassing.sync="isPassing"
          :showRefresh="true"
          :barWidth="40"
          :text="$t('please_hold_down_the_slider_and_drag')"
          :successText="$t('verificate_pass')"
          :failTip="$t('verification_fails_tips')"
          :successTip="$t('verification_passed_more_than_of_users', { scale: '85%' })"
          handlerIcon="la la-arrow-right"
          successIcon="la la-check"
          @refresh="refreshVerifyImage"
          @passcallback="verificationPass"
        />
      </v-card>
    </v-dialog>

    <!--  -->
    <a class="open_seller d-lg-none" href="/register-shop">
      <img src="@/assets/img/seller_login.png" class="menu-icon me-4" />
      {{ $t('open_online_store_as_a_seller') }}
    </a>
  </div>
</template>

<script>
import {
  required,
  requiredIf,
  email,
  minLength,
  maxLength,
  sameAs
} from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { VueTelInput } from 'vue-tel-input'
import SocialLogin from '../../components/auth/SocialLogin.vue'

// 图片拖拽验证组件
import DragVerifyImgChip from '@/components/auth/DragVerifyImgChip'

// import snackbar from "../../components/inc/SnackBar.vue";
export default {
  data: () => ({
    mobileInputProps: {
      inputOptions: {
        type: 'tel',
        placeholder: 'phone number'
      },
      dropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: true,
        showDialCodeInList: true
      },
      autoDefaultCountry: false,
      validCharactersOnly: true,
      mode: 'international'
    },
    form: {
      name: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: '',
      invalidPhone: false,
      showInvalidPhone: false,
      verificationCode: ''
    },
    passwordShow: false,
    loading: false,

    // 图片验证是否通过
    isPassing: false,

    // dialog 开关
    dialogSwitch: false,

    // 是否展示切换手机/邮箱开关
    showRegisterTypeSwitch: false,

    // 验证显示开关
    registerVerifySwitch: false,

    // 倒计时描述
    countdownSeconds: 60,

    // 国家区号
    dialCode: ''
  }),
  
  components: {
    // snackbar,
    VueTelInput,
    DragVerifyImgChip,
    SocialLogin
  },

  watch: {
    'form.phone'(value) {
      if (String(value).indexOf('0') === 0) {
        this.form.phone = this.form.phone.substring(1)
      }
    }
  },

  validations: {
    form: {
      email:{ required, email },
      phone: { required },
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs('password') },
      verificationCode: { required: requiredIf(function() {
        return this.registerVerifySwitch
      }), maxLength: maxLength(5) }
    }
  },

  computed: {
    ...mapGetters('app', ['generalSettings', 'availableCountries', 'emailVerification']),
    ...mapGetters('auth', ['authSettings']),
    ...mapGetters('cart', ['getTempUserId']),

    countdownText() {
      return this.countdownSeconds === 60 ? this.$t('send') : `${this.countdownSeconds}s`
    },

    nameErrors() {
      const errors = []
      if (!this.$v.form.name.$dirty) return errors
      !this.$v.form.name.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      !this.$v.form.email.email &&
        errors.push(this.$i18n.t('this_field_is_required_a_valid_email'))
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      !this.$v.form.password.minLength &&
        errors.push(this.$i18n.t('password_must_be_minimum_6_characters'))
      return errors
    },
    confirmPasswordErrors() {
      const errors = []
      if (!this.$v.form.confirmPassword.$dirty) return errors
      !this.$v.form.confirmPassword.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      !this.$v.form.confirmPassword.sameAsPassword &&
        errors.push(this.$i18n.t('password_and_confirm_password_should_match'))
      return errors
    },
    verificationCodeErrors() {
      const errors = []
      if (!this.$v.form.verificationCode.$dirty) return errors
      !this.$v.form.verificationCode.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      !this.$v.form.verificationCode.maxLength &&
        errors.push(this.$i18n.t('verification_code_is_5_characters'))
      return errors
    }
  },

  methods: {
    ...mapActions('auth', ['login']),
    ...mapMutations('cart', ['removeTempUserId']),
    ...mapMutations('auth', [
      'updateChatWindow',
      'showLoginDialog',
      'setAuthSettings',
      'setAuthSettingsVerify'
    ]),

    // 电话区号改变
    onCountryChanged({ dialCode }) {
      this.dialCode = dialCode
    },

    phoneValidate(phone) {
      console.log('phone ::: ', phone)

      this.form.invalidPhone = !phone.valid
      this.form.showInvalidPhone = !phone.valid
    },

    // 验证图片刷新
    refreshVerifyImage() {},

    // 验证通过
    verificationPass() {
      this.countdown()

      setTimeout(() => {
        this.dialogSwitch = false

        this.sendVerificationCode()
      }, 1000)
    },

    // 根据当前的注册，自动发送对应的验证码
    async sendVerificationCode() {
      try {
        const methods = this.authSettings.customer_login_with === 'phone' ? 'sms/send' : 'email/send'
    
        const params = {
          tag: 'register',
          user_type: 'customer'
        }

        if (this.authSettings.customer_login_with === 'phone') {
          params.phone = `+${this.dialCode}${this.form.phone.replace(/\s/g, '')}`
        } else {
          params.email = this.form.email
        }

        const { data: { result } } = await this.call_api('post', methods, params)

        if (result) {
          return this.snack({
            message: this.$t('verification_code_has_send_success'),
            color: 'green'
          })
        }

        this.snack({
          message: this.$t('verification_code_has_send_fail'),
          color: 'red'
        })
      } catch (error) {
        console.log('send verification code fail: ', error)

        this.snack({
          message: this.$t('verification_code_has_send_fail'),
          color: 'red'
        })
      }
    },

    // 倒计时
    countdown() {
      this.interval = setInterval(() => {
        this.countdownSeconds--

        // 倒计时秒数为 0，则清空计时器
        if (!this.countdownSeconds) {
          clearInterval(this.interval)
          this.countdownSeconds = 60

          return
        }
      }, 1000)
    },

    onSend() {
      if (this.authSettings.customer_login_with === 'phone') {
        this.$v.form.phone.$touch()

        if (this.$v.form.phone.$error || this.form.invalidPhone) return
      } else {
        this.$v.form.email.$touch()

        if (this.$v.form.email.$error) return
      }

      this.dialogSwitch = true

      this.$nextTick(() => {
        this.$refs.dvic.reset()
      })
    },

    // 注册配置
    async registerConfig() {
      try {
        const { data: { data: { customer_register_type, customer_register_verify } } } = await  this.call_api('get', `register/config?tag=customer`)

        switch (customer_register_type) {
          case 2:
            this.setAuthSettingsVerify('phone')
            break
          case 3:
            this.showRegisterTypeSwitch = true
            break
          default:
            break
        }

        // 注册验证
        this.registerVerifySwitch = !!customer_register_verify
      } catch (error) {
        console.log('register config error: ', error)
      }
    },

    async register() {
      this.$v.form.$touch()

      if (
        (this.authSettings.customer_login_with == 'phone' ||
          this.authSettings.customer_login_with == 'email_phone') &&
        this.form.invalidPhone
      ) {
        this.form.showInvalidPhone = true
        return
      }

      if (
        this.$v.form.name.$error
        || this.$v.form.password.$error
        || this.$v.form.confirmPassword.$error
        || this.$v.form.verificationCode.$error
        || (this.authSettings.customer_login_with == 'email' && this.$v.form.email.$error) 
        || (this.authSettings.customer_login_with == 'phone' && this.$v.form.phone.$error) 
      ) {
        return
      }

      this.form.phone = this.form.phone.replace(/\s/g, '')

      if (this.getTempUserId) {
        this.form.temp_user_id = this.getTempUserId
      }
      this.loading = true

      // 如果 url 有邀请码，把邀请码带上
      if (this.referralCode) {
        this.form.referral_code = this.referralCode
      }

      // 多加一个注册类型字段 customer | seller
      this.form.register_type  = 'customer'

      // 如果是电话号码注册，则带上区号
      const params = { ...this.form }
      if (this.authSettings.customer_login_with == 'phone') {
        params.phone = `+${this.dialCode}${this.form.phone}`
        params.email = ''
      } else {
        params.phone = ''
      }

      const res = await this.call_api('post', 'register', params)

      if (res.data.success) {
        if (this.getTempUserId) {
          this.removeTempUserId()
        }

        this.login(res.data)
        this.showLoginDialog(false)
        this.updateChatWindow(false)
        this.$router.push(this.$route.query.redirect || { name: 'DashBoard' })

      } else {
        this.snack({
          message: res.data.message,
          color: 'red'
        })
      }
      this.loading = false
    },
    // 手机邮箱切换
    switchMode() {
      this.setAuthSettings()
    }
  },

  created() {
    const query = this.$route.query

    if (query.referral_code) {
      this.referralCode = query.referral_code
    }

    this.registerConfig()
    this.showLoginDialog(false)
  }
}
</script>

<style lang="scss" scoped>
  .dialog-container {
    padding: 30px;
  }

  .dialog-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 6px;
  }

  ::v-deep .drag-verify-container {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
  }
  .open_seller {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 62px;
    height: 45px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    color: red;
    font-size: 14px;
    z-index: 11;
  }
  .open_seller > img {
    width: 20px;
    margin-left: 10px;
  }

  .fc-vi {
    color: #FF3B3B;
  }

  .vue-tel-input{
    background: #ECFFFC !important;
  }
</style>
